import { Game, Move as GameMove } from '@shared/game-engine';
import HostedGame from '../shared/app/models/HostedGame';
import { HostedGameToPlayer, Move } from '../shared/app/models';
import { TypedEmitter } from 'tiny-typed-emitter';
import { apiPostAnswerUndo, apiPostAskUndo, apiPostCancel, apiPostResign } from './apiClient';
import { notifier } from './services/notifications';
import useServerDateStore from './stores/serverDateStore';
import { timeValueToMilliseconds } from '../shared/time-control/TimeValue';
import { toEngineMove } from '../shared/app/models/Move';
/**
 * Contains info to display in the games list on lobby (HostedGame).
 * If needed, can also download full game data and start listening to game events (Game).
 */
export default class HostedGameClient extends TypedEmitter {
    constructor(hostedGame, socket) {
        super();
        this.hostedGame = hostedGame;
        this.socket = socket;
        /**
         * Null if game data not fully loaded yet, i.e for lobby list display.
         * Game data can still be retrieved in hostedGame.
         */
        this.game = null;
        this.lowTimeNotificationThread = null;
        this.readMessages = hostedGame.chatMessages.length;
    }
    getState() {
        return this.hostedGame.state;
    }
    getPlayerIndex(player) {
        return this.hostedGame.hostedGameToPlayers.findIndex(p => p.player.publicId === player.publicId);
    }
    loadGame() {
        var _a;
        return (_a = this.game) !== null && _a !== void 0 ? _a : this.loadGameFromData(this.hostedGame);
    }
    loadGameFromData(hostedGame) {
        var _a, _b, _c;
        const { gameData } = hostedGame;
        /**
         * No game server side, create an empty one to show client side
         */
        if (null === gameData) {
            this.game = new Game(hostedGame.gameOptions.boardsize);
            // Cancel here in case game has been canceled before started
            if ('canceled' === hostedGame.state) {
                this.game.cancel(hostedGame.createdAt);
            }
            return this.game;
        }
        this.game = new Game(gameData.size);
        this.game.setAllowSwap(gameData.allowSwap);
        this.game.setStartedAt(gameData.startedAt);
        this.doStartGame(hostedGame);
        // Replay game and fill history
        for (const move of gameData.movesHistory) {
            this.game.move(GameMove.fromData(move), this.game.getCurrentPlayerIndex());
        }
        // Cancel game if canceled
        if ('canceled' === hostedGame.state && !this.game.isEnded()) {
            this.game.cancel((_b = (_a = gameData.endedAt) !== null && _a !== void 0 ? _a : gameData.lastMoveAt) !== null && _b !== void 0 ? _b : new Date());
        }
        // Set a winner if not yet set because timeout or resignation
        if (null !== gameData.winner && !this.game.isEnded()) {
            this.game.declareWinner(gameData.winner, gameData.outcome, (_c = gameData.endedAt) !== null && _c !== void 0 ? _c : new Date());
        }
        return this.game;
    }
    getId() {
        return this.hostedGame.publicId;
    }
    getPlayers() {
        return this.hostedGame.hostedGameToPlayers.map(hostedGameToPlayer => hostedGameToPlayer.player);
    }
    getPlayer(position) {
        var _a;
        return (_a = this.hostedGame.hostedGameToPlayers[position].player) !== null && _a !== void 0 ? _a : null;
    }
    getWinnerPlayer() {
        var _a, _b;
        if (((_a = this.hostedGame.gameData) === null || _a === void 0 ? void 0 : _a.winner) !== 0 && ((_b = this.hostedGame.gameData) === null || _b === void 0 ? void 0 : _b.winner) !== 1) {
            return null;
        }
        return this.hostedGame.hostedGameToPlayers[this.hostedGame.gameData.winner].player;
    }
    getStrictWinnerPlayer() {
        var _a, _b;
        if (((_a = this.hostedGame.gameData) === null || _a === void 0 ? void 0 : _a.winner) !== 0 && ((_b = this.hostedGame.gameData) === null || _b === void 0 ? void 0 : _b.winner) !== 1) {
            throw new Error('getStrictWinnerPlayer(): No winner');
        }
        return this.hostedGame.hostedGameToPlayers[this.hostedGame.gameData.winner].player;
    }
    getLoserPlayer() {
        var _a, _b;
        if (((_a = this.hostedGame.gameData) === null || _a === void 0 ? void 0 : _a.winner) !== 0 && ((_b = this.hostedGame.gameData) === null || _b === void 0 ? void 0 : _b.winner) !== 1) {
            return null;
        }
        return this.hostedGame.hostedGameToPlayers[1 - this.hostedGame.gameData.winner].player;
    }
    hasPlayer(player) {
        return this.hostedGame.hostedGameToPlayers.some(p => p.player.publicId === player.publicId);
    }
    /**
     * Returns player in this game who is playing against player.
     * Or null if player is not in the game, or game has not yet 2 players.
     */
    getOtherPlayer(player) {
        if (2 !== this.hostedGame.hostedGameToPlayers.length) {
            return null;
        }
        if (this.hostedGame.hostedGameToPlayers[0].player.publicId === player.publicId) {
            return this.hostedGame.hostedGameToPlayers[1].player;
        }
        return this.hostedGame.hostedGameToPlayers[0].player;
    }
    getHostedGame() {
        return this.hostedGame;
    }
    getGameOptions() {
        return this.hostedGame.gameOptions;
    }
    getChatMessages() {
        return this.hostedGame.chatMessages;
    }
    isRanked() {
        return this.hostedGame.gameOptions.ranked;
    }
    getRatings() {
        var _a;
        return (_a = this.hostedGame.ratings) !== null && _a !== void 0 ? _a : [];
    }
    /**
     * Update data and game from HostedGame
     */
    updateFromHostedGame(hostedGame) {
        this.hostedGame = hostedGame;
    }
    getGame() {
        if (null === this.game) {
            return this.loadGame();
        }
        return this.game;
    }
    canResign() {
        return this.hostedGame.state === 'playing';
    }
    canCancel() {
        if (null === this.game) {
            return true;
        }
        return !this.game.isCanceled()
            && this.hostedGame.state !== 'ended'
            && this.getGame().getMovesHistory().length < 2;
    }
    canRematch() {
        return (this.hostedGame.state === 'ended'
            || this.hostedGame.state === 'canceled')
            && this.hostedGame.rematch == null;
    }
    getRematchGameId() {
        var _a, _b;
        return (_b = (_a = this.hostedGame.rematch) === null || _a === void 0 ? void 0 : _a.publicId) !== null && _b !== void 0 ? _b : null;
    }
    canJoin(player) {
        if (!player) {
            return false;
        }
        // Cannot join if game has been canceled
        if ('canceled' === this.hostedGame.state) {
            return false;
        }
        // Cannot join as my own opponent
        if (this.hasPlayer(player)) {
            return false;
        }
        // Cannot join if game is full
        if (this.hostedGame.hostedGameToPlayers.length >= 2) {
            return false;
        }
        return true;
    }
    /**
     * Join a game to play if there is a free slot.
     */
    async sendJoinGame() {
        return new Promise((resolve, reject) => {
            this.socket.emit('joinGame', this.getId(), (answer) => {
                if (true === answer) {
                    resolve(answer);
                }
                reject(answer);
            });
        });
    }
    async sendMove(move) {
        // No need to send client playedAt date, server won't trust it
        const moveWithoutDate = new Move();
        moveWithoutDate.row = move.row;
        moveWithoutDate.col = move.col;
        moveWithoutDate.specialMoveType = move.specialMoveType;
        return new Promise((resolve, reject) => {
            this.socket.emit('move', this.getId(), moveWithoutDate, answer => {
                if (true === answer) {
                    resolve(answer);
                }
                reject(answer);
            });
            notifier.emit('move', this.hostedGame, moveWithoutDate);
        });
    }
    async sendAskUndo() {
        return apiPostAskUndo(this.getId());
    }
    async sendAnswerUndo(accept) {
        return apiPostAnswerUndo(this.getId(), accept);
    }
    getUndoRequest() {
        return this.hostedGame.undoRequest;
    }
    async sendResign() {
        return apiPostResign(this.getId());
    }
    async sendCancel() {
        return apiPostCancel(this.getId());
    }
    onServerPlayerJoined(player) {
        const hostedGameToPlayer = new HostedGameToPlayer();
        hostedGameToPlayer.hostedGame = this.hostedGame;
        hostedGameToPlayer.player = player;
        this.hostedGame.hostedGameToPlayers.push(hostedGameToPlayer);
    }
    doStartGame(hostedGame) {
        this.updateFromHostedGame(hostedGame);
        const { gameData } = hostedGame;
        if (null === gameData) {
            throw new Error('game started but no game data');
        }
        // Do nothing if game not yet loaded
        if (null === this.game) {
            return;
        }
        this.hostedGame.hostedGameToPlayers = hostedGame.hostedGameToPlayers;
    }
    onServerGameStarted(hostedGame) {
        this.doStartGame(hostedGame);
        this.emit('started');
        notifier.emit('gameStart', hostedGame);
    }
    onServerGameCanceled(date) {
        this.hostedGame.state = 'canceled';
        if (this.hostedGame.gameData) {
            this.hostedGame.gameData.endedAt = date;
        }
        if (null !== this.game && !this.game.isCanceled()) {
            this.game.cancel(date);
        }
    }
    getTimeControlOptions() {
        return this.hostedGame.gameOptions.timeControl;
    }
    getTimeControlValues() {
        return this.hostedGame.timeControl;
    }
    onServerUpdateTimeControl(gameTimeData) {
        Object.assign(this.hostedGame.timeControl, gameTimeData);
        this.notifyWhenLowTime(gameTimeData);
    }
    resetLowTimeNotificationThread() {
        if (null !== this.lowTimeNotificationThread) {
            clearTimeout(this.lowTimeNotificationThread);
            this.lowTimeNotificationThread = null;
        }
    }
    notifyWhenLowTime(gameTimeData) {
        this.resetLowTimeNotificationThread();
        const { players, currentPlayer } = gameTimeData;
        const { totalRemainingTime } = players[currentPlayer];
        if (!(totalRemainingTime instanceof Date)) {
            return;
        }
        const serverDate = useServerDateStore().newDate();
        this.lowTimeNotificationThread = setTimeout(() => {
            notifier.emit('gameTimeControlWarning', this.hostedGame);
        }, timeValueToMilliseconds(totalRemainingTime, serverDate) - 10000);
    }
    onServerRematchAvailable(rematchId) {
        const hostedGame = new HostedGame();
        hostedGame.publicId = rematchId;
        this.hostedGame.rematch = hostedGame;
    }
    onServerGameMoved(move, moveIndex, byPlayerIndex) {
        const { gameData } = this.hostedGame;
        if (null !== gameData) {
            gameData.movesHistory.push(move);
            gameData.currentPlayerIndex = 1 - byPlayerIndex;
            gameData.lastMoveAt = move.playedAt;
        }
        // Do nothing if game not loaded
        if (null === this.game) {
            return;
        }
        // Ignore server move because already played locally
        if (moveIndex <= this.game.getLastMoveIndex()) {
            return;
        }
        this.game.move(toEngineMove(move), byPlayerIndex);
        notifier.emit('move', this.hostedGame, move);
    }
    onServerAskUndo(byPlayerIndex) {
        this.hostedGame.undoRequest = byPlayerIndex;
    }
    onServerAnswerUndo(accept) {
        if (accept && this.game) {
            if (null === this.hostedGame.undoRequest) {
                throw new Error('undo answered but no undo request');
            }
            this.game.playerUndo(this.hostedGame.undoRequest);
        }
        this.hostedGame.undoRequest = null;
    }
    onServerCancelUndo() {
        this.hostedGame.undoRequest = null;
    }
    onServerGameEnded(winner, outcome, date) {
        this.hostedGame.state = 'ended';
        if (this.hostedGame.gameData) {
            this.hostedGame.gameData.winner = winner;
            this.hostedGame.gameData.outcome = outcome;
            this.hostedGame.gameData.endedAt = date;
        }
        // Do nothing if game not loaded
        if (null === this.game) {
            return;
        }
        notifier.emit('gameEnd', this.hostedGame);
        // If game is not already ended locally by server response anticipation
        if (this.game.isEnded()) {
            return;
        }
        this.game.declareWinner(winner, outcome, date);
    }
    onRatingsUpdated(ratings) {
        // Add rating change of this game
        this.hostedGame.ratings = ratings;
        // Update player current rating to update view
        ratings.forEach(rating => {
            var _a;
            const player = (_a = this.hostedGame
                .hostedGameToPlayers
                .find(hostedGameToPlayer => hostedGameToPlayer.player.publicId === rating.player.publicId)) === null || _a === void 0 ? void 0 : _a.player;
            if (!player) {
                return;
            }
            player.currentRating = rating;
        });
    }
    async sendChatMessage(content) {
        return new Promise((resolve, reject) => {
            this.socket.emit('sendChat', this.hostedGame.publicId, content, (answer) => {
                if (true === answer) {
                    resolve(answer);
                }
                reject(answer);
            });
        });
    }
    onChatMessage(chatMessage) {
        this.hostedGame.chatMessages.push(chatMessage);
        this.emit('chatMessagePosted');
        notifier.emit('chatMessage', this.hostedGame, chatMessage);
    }
    getUnreadMessages() {
        return this.readMessages - this.hostedGame.chatMessages.length;
    }
    getReadMessages() {
        return this.readMessages;
    }
    markAllMessagesRead() {
        this.readMessages = this.hostedGame.chatMessages.length;
    }
}
