var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Column, Entity, Index, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';
import HostedGame from './HostedGame';
import { Expose, plainToInstance } from '../class-transformer-custom';
import { Type } from 'class-transformer';
import Move from './Move';
let Game = class Game {
    constructor() {
        this.winner = null;
        this.outcome = null;
        this.startedAt = new Date();
        this.lastMoveAt = null;
        this.endedAt = null;
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], Game.prototype, "hostedGameId", void 0);
__decorate([
    OneToOne(() => HostedGame),
    JoinColumn(),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], Game.prototype, "hostedGame", void 0);
__decorate([
    Column('smallint'),
    Expose(),
    __metadata("design:type", Number)
], Game.prototype, "size", void 0);
__decorate([
    Column({ type: 'json', transformer: { from: (value) => deserializeMovesHistory(value), to: value => value } }),
    Expose(),
    Type(() => Move),
    __metadata("design:type", Array)
], Game.prototype, "movesHistory", void 0);
__decorate([
    Column(),
    Expose(),
    __metadata("design:type", Boolean)
], Game.prototype, "allowSwap", void 0);
__decorate([
    Column({ type: 'smallint' }),
    Expose(),
    __metadata("design:type", Object)
], Game.prototype, "currentPlayerIndex", void 0);
__decorate([
    Column({ type: 'smallint', nullable: true }),
    Expose(),
    __metadata("design:type", Object)
], Game.prototype, "winner", void 0);
__decorate([
    Column({ type: String, length: 15, nullable: true }),
    Expose(),
    __metadata("design:type", Object)
], Game.prototype, "outcome", void 0);
__decorate([
    Column({ type: Date, default: () => 'current_timestamp(3)', precision: 3 }),
    Expose(),
    Type(() => Date),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], Game.prototype, "startedAt", void 0);
__decorate([
    Column({ type: Date, precision: 3, nullable: true }),
    Expose(),
    Type(() => Date),
    __metadata("design:type", Object)
], Game.prototype, "lastMoveAt", void 0);
__decorate([
    Column({ type: Date, precision: 3, nullable: true }),
    Index(),
    Expose(),
    Type(() => Date),
    __metadata("design:type", Object)
], Game.prototype, "endedAt", void 0);
Game = __decorate([
    Entity()
], Game);
export default Game;
const deserializeMovesHistory = (value) => {
    if (!value) {
        return [];
    }
    if (!(value instanceof Array)) {
        throw new Error('Expected an array here');
    }
    return value.map(v => plainToInstance(Move, v));
};
