/**
 * - Locale key must have same name a translation file
 * - Label is displayed to player in select menu
 * - Loader is file used for date-fns, to translate date/times
 */
export const availableLocales = {
    en: {
        label: 'English',
        loader: () => import(/* webpackChunkName: "locale-en" */ 'date-fns/locale/en-US'),
    },
    fr: {
        label: 'Français',
        loader: () => import(/* webpackChunkName: "locale-fr" */ 'date-fns/locale/fr'),
    },
    de: {
        label: 'German',
        loader: () => import(/* webpackChunkName: "locale-de" */ 'date-fns/locale/de'),
    },
    'zh-Hans': {
        label: 'Chinese (beta)',
        loader: () => import(/* webpackChunkName: "locale-zh" */ 'date-fns/locale/zh-CN'),
    },
    pl: {
        label: 'Polish',
        loader: () => import(/* webpackChunkName: "locale-pl" */ 'date-fns/locale/pl'),
    },
    pt: {
        label: 'Portuguese (beta)',
        loader: () => import(/* webpackChunkName: "locale-pt" */ 'date-fns/locale/pt'),
    },
    es: {
        label: 'Spanish',
        loader: () => import(/* webpackChunkName: "locale-es" */ 'date-fns/locale/es'),
    },
};
