import { defineStore } from 'pinia';
import HostedGameClient from '@client/HostedGameClient';
import { apiPostGame, apiPostRematch, getEndedGames, getGame, getGames } from '@client/apiClient';
import useSocketStore from './socketStore';
import { ref } from 'vue';
import Rooms from '@shared/app/Rooms';
/**
 * State synced with server, and methods to handle games and players.
 */
const useLobbyStore = defineStore('lobbyStore', () => {
    const { socket, joinRoom } = useSocketStore();
    /**
     * List of games.
     */
    const hostedGameClients = ref({});
    const createGame = async (gameOptions) => {
        const hostedGame = await apiPostGame(gameOptions);
        hostedGameClients.value[hostedGame.publicId] = new HostedGameClient(hostedGame, socket);
        return hostedGameClients.value[hostedGame.publicId];
    };
    const rematchGame = async (gameId) => {
        const hostedGameData = await apiPostRematch(gameId);
        hostedGameClients.value[hostedGameData.publicId] = new HostedGameClient(hostedGameData, socket);
        return hostedGameClients.value[hostedGameData.publicId];
    };
    /**
     * Promise of list of games loaded on app start.
     * Can be reused.
     */
    const initialGamesPromise = getGames();
    /**
     * Load and update all games from server.
     */
    const updateGames = async () => {
        const apiGames = await initialGamesPromise;
        apiGames.forEach(hostedGame => {
            if (hostedGameClients.value[hostedGame.publicId]) {
                hostedGameClients.value[hostedGame.publicId].updateFromHostedGame(hostedGame);
            }
            else {
                hostedGameClients.value[hostedGame.publicId] = new HostedGameClient(hostedGame, socket);
            }
        });
    };
    /**
     * Get a game from store, or from server if not yet in store.
     */
    const retrieveHostedGameClient = async (gameId, forceReload = false) => {
        if (hostedGameClients.value[gameId] && !forceReload) {
            return hostedGameClients.value[gameId];
        }
        const hostedGame = await getGame(gameId);
        if (null === hostedGame) {
            return null;
        }
        return hostedGameClients.value[gameId] = new HostedGameClient(hostedGame, socket);
    };
    /**
     * Load more finished games, excluding bot games.
     */
    const loadMoreEndedGames = async () => {
        var _a, _b, _c, _d, _e;
        let oldestHostedGameClient = undefined;
        for (const publicId in hostedGameClients.value) {
            if (hostedGameClients.value[publicId].getState() !== 'ended') {
                continue;
            }
            if (undefined === oldestHostedGameClient) {
                oldestHostedGameClient = hostedGameClients.value[publicId];
                continue;
            }
            const oldestDate = (_b = (_a = oldestHostedGameClient.getHostedGame().gameData) === null || _a === void 0 ? void 0 : _a.endedAt) === null || _b === void 0 ? void 0 : _b.getTime();
            const currentDate = (_d = (_c = hostedGameClients.value[publicId].getHostedGame().gameData) === null || _c === void 0 ? void 0 : _c.endedAt) === null || _d === void 0 ? void 0 : _d.getTime();
            if (undefined === oldestDate || undefined === currentDate) {
                continue;
            }
            if (currentDate < oldestDate) {
                oldestHostedGameClient = hostedGameClients.value[publicId];
            }
        }
        const moreEndedGames = await getEndedGames(20, (_e = oldestHostedGameClient === null || oldestHostedGameClient === void 0 ? void 0 : oldestHostedGameClient.getId()) !== null && _e !== void 0 ? _e : null);
        moreEndedGames.forEach(endedGame => {
            hostedGameClients.value[endedGame.publicId] = new HostedGameClient(endedGame, socket);
        });
    };
    const listenSocket = () => {
        socket.on('gameCreated', (hostedGame) => {
            hostedGameClients.value[hostedGame.publicId] = new HostedGameClient(hostedGame, socket);
        });
        socket.on('gameJoined', (gameId, player) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerPlayerJoined(player);
            }
        });
        socket.on('gameStarted', (hostedGame) => {
            if (hostedGameClients.value[hostedGame.publicId]) {
                hostedGameClients.value[hostedGame.publicId].onServerGameStarted(hostedGame);
            }
        });
        socket.on('gameCanceled', (gameId, { date }) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerGameCanceled(date);
            }
        });
        socket.on('moved', (gameId, move, moveIndex, byPlayerIndex) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerGameMoved(move, moveIndex, byPlayerIndex);
            }
        });
        socket.on('askUndo', (gameId, byPlayerIndex) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerAskUndo(byPlayerIndex);
            }
        });
        socket.on('answerUndo', (gameId, accept) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerAnswerUndo(accept);
            }
        });
        socket.on('cancelUndo', (gameId) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerCancelUndo();
            }
        });
        socket.on('timeControlUpdate', (gameId, gameTimeData) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerUpdateTimeControl(gameTimeData);
            }
        });
        socket.on('rematchAvailable', (gameId, rematchId) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerRematchAvailable(rematchId);
            }
        });
        socket.on('ended', (gameId, winner, outcome, { date }) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onServerGameEnded(winner, outcome, date);
            }
        });
        socket.on('ratingsUpdated', (gameId, ratings) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onRatingsUpdated(ratings);
            }
        });
        socket.on('chat', (gameId, chatMessage) => {
            if (hostedGameClients.value[gameId]) {
                hostedGameClients.value[gameId].onChatMessage(chatMessage);
            }
        });
    };
    // Listen lobby event to update state on change
    listenSocket();
    // Get lobby updates
    joinRoom(Rooms.lobby);
    // Load games on vue app open
    updateGames();
    return {
        hostedGameClients,
        initialGamesPromise,
        createGame,
        rematchGame,
        updateGames,
        retrieveHostedGameClient,
        loadMoreEndedGames,
    };
});
export default useLobbyStore;
